/////////////
// Colours //
/////////////
$color-aqua: #7fdbff;
$color-black: #111;
$color-black-light: #191919;
$color-blue: #0074d9;
$color-grey: #eee;
$color-grey-darker: #ddd;
$color-grey-darkest: #222;
$color-navy: #001f3f;
$color-pink: #f012be;
$color-olive: #3d9970;
$color-white: #fff;

///////////
// Fonts //
///////////
$font-ibm-plex-mono: "IBM Plex Mono Regular";
