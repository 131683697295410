@import "variables";

@font-face {
  font-family: $font-ibm-plex-mono;
  src: url(../fonts/ibm-plex-mono-regular.eot);
  src: url(../fonts/ibm-plex-mono-regular.eot?#iefix) format("embedded-opentype"),
       url(../fonts/ibm-plex-mono-regular.woff2) format("woff2"),
       url(../fonts/ibm-plex-mono-regular.ttf) format("truetype"),
       url(../fonts/ibm-plex-mono-regular.woff) format("woff"),
       url(../fonts/ibm-plex-mono-regular.svg#IBMPlexMono) format("svg");
}

@font-face {
  font-family: $font-ibm-plex-mono;
  font-style: italic;
  src: url(../fonts/ibm-plex-mono-italic.eot);
  src: url(../fonts/ibm-plex-mono-italic.eot?#iefix) format("embedded-opentype"),
       url(../fonts/ibm-plex-mono-italic.woff2) format("woff2"),
       url(../fonts/ibm-plex-mono-italic.ttf) format("truetype"),
       url(../fonts/ibm-plex-mono-italic.woff) format("woff"),
       url(../fonts/ibm-plex-mono-italic.svg#IBMPlexMono) format("svg");
}

@font-face {
  font-family: $font-ibm-plex-mono;
  font-weight: bold;
  src: url(../fonts/ibm-plex-mono-bold.eot);
  src: url(../fonts/ibm-plex-mono-bold.eot?#iefix) format("embedded-opentype"),
       url(../fonts/ibm-plex-mono-bold.woff2) format("woff2"),
       url(../fonts/ibm-plex-mono-bold.ttf) format("truetype"),
       url(../fonts/ibm-plex-mono-bold.woff) format("woff"),
       url(../fonts/ibm-plex-mono-bold.svg#IBMPlexMono) format("svg");
}
